<template>
  <div class="content-purchase-orders">
    <skeleton-filter-sales-orders-component />
    <div>
      <v-skeleton-loader
        type="table"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iTab: Number,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.content-purchase-orders {
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0px 12px 0px 12px;
}
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-purchase-orders {
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 0px 10px 0px 10px;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>